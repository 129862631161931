import { Box, useTheme } from '@mui/material';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon';

export function ParkingaboLogoHeader() {
    const theme = useTheme();

    return (
        <Box
            style={{
                background: theme.palette.primary.dark,
                paddingLeft: theme.spacing(3),
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            }}
        >
            <ParkingaboLogo width={313} height={66} />
        </Box>
    );
}

export function ParkingaboLogo({
    width,
    height,
}: {
    width: number;
    height: number;
}) {
    return (
        <Box
            sx={{
                width: width,
                height: height,
            }}
        >
            <SvgIcon icon={'parkingabo'} width={2473} height={522} />
        </Box>
    );
}
